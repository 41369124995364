.custom-data-table {
    .MuiTableCell-root {
        @extend .border-bottom-1;
        @extend .padding-16;
        @extend .f-14;
        @extend .fw-400;
        @extend .color-content;

        p {
            @extend .f-14;
            @extend .fw-400;
            @extend .color-content;
            @extend .align-items-center;
        }
    }

    td {
        @extend .f-14;
        @extend .fw-400;
        @extend .color-content;
    }

    .table-footer {
        @extend .border-top-1;

        .custom-pagination {
            p {
                @extend .margin-0;
                @extend .f-14;
                @extend .fw-400;
                @extend .color-content;
            }

            .MuiTablePagination-select {
                @extend .border-1;
                @extend .border-radius-5;
            }
        }
    }
}
.tableBodyCell {
    border: 0px;
}
.tableHeadCell {
    border: 0px;
    @extend .bg-white;
    & .MuiSvgIcon-root {
        @extend .cursor-pointer;
    }
    & .MuiStack-root {
        @extend .position-relative;

        & .MuiFormControl-root {
            position: 'absolute';
            top: '45px';
            left: '0';
            box-shadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);';
            width: '230px';
            padding: '17px 16px';
            overflow: 'hidden';
            overflow-y: 'auto';
            @extend .border-radius-4;
            @extend .bg-white;
        }
    }

    & .MuiMenuItem-root {
        @extend .padding-0;
    }
}
