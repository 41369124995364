@media (min-width: 900px) {
    .display-web-none {
        display: none;
    }
}

@media (max-width: 900px) {
    .display-mobile-none {
        display: none;
    }

    .display-mobile {
        display: inline-block;
    }

    /* UserMobileView start */
    .mobile-usersListing-wrapper {
        margin-top: 10px;
        li {
            background-color: #fff;
            padding: 24px;
        }
        .BluiInfoListItem-root {
            .MuiListItemText-root {
                .BluiInfoListItem-title {
                    color: #333d43;
                    font-weight: 600;
                    font-size: 14px;
                    .MuiTypography-root {
                        color: #333d43;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
                .BluiInfoListItem-subtitle {
                    color: #333d43;
                }
                .BluiInfoListItem-info {
                    color: #333d43;
                    font-size: 12px;
                }
            }
        }
    }
    /* UserMobileView end */

    /* RoleMobileView start */
    .roles-listing-wrapper {
        padding: 0px !important;
        margin-top: 9px;
        li {
            background-color: #fff;
        }

        .BluiInfoListItem-root {
            .MuiListItemAvatar-root {
                display: none;
            }
            .MuiListItemText-root {
                .BluiInfoListItem-title {
                    .role-name-wrapper {
                        color: #556167;
                        font-weight: 600;
                        min-width: 100%;
                    }
                    .role-count-wrapper {
                        font-size: 14px;
                        gap: 5px;
                    }
                }
                .BluiInfoListItem-subtitle {
                    color: #424e54;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    /* RoleMobileView end */

    .user-list-wrapper {
        .MuiBreadcrumbs-separator,
        .MuiBreadcrumbs-li {
            padding: 0px;
            margin: 0px 2px;
        }
        li {
            height: auto;
            padding: 0px 16px;
        }

        .MuiListItemAvatar-root {
            display: none;
        }

        .BluiInfoListItem-info {
            .MuiSvgIcon-root,
            .MuiTypography-body1 {
                font-weight: 400;
                font-size: 14px;
                color: #424e54;
            }

            .MuiSvgIcon-root {
                margin: 0 0px;
            }
        }
    }

    /*EditUserProfile start */
    .mobile-userProfile-wrapper {
        width: auto !important;
        padding: 0px;
        .MuiPaper-root {
            margin: 0px;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            box-shadow: none;
        }
        .roles-section {
            margin: 16px 0px;
        }
    }
    /*EditUserProfile end */

    /*AddorEditRole start*/
    .role-wrapper {
        .MuiFormHelperText-root {
            text-align: end;
        }
    }
    .privilege-wrapper {
        .MuiList-root {
            padding: 0px;
            .MuiListItem-root {
                padding: 8px 16px 8px 32px;
            }
        }
    }
    .user-wrapper {
        .user-list-wrapper {
            padding: 0;
            li {
                padding: 0px 16px 0px 32px;
                .BluiInfoListItem-title {
                    color: #424e54;
                }
                .BluiInfoListItem-subtitle {
                    color: #333d43 !important;
                }
            }
            .MuiBreadcrumbs-separator,
            .MuiBreadcrumbs-li {
                padding: 0px;
                margin: 0px 2px;
            }
        }
    }

    /*AddorEditRole end*/
}

@media (max-width: 900px) {
    .css-cuefkz-MuiContainer-root {
        max-width: 100%;
    }

    .mobile-EditUserProfile-wrapper {
        height: calc(100vh - 174px);
        overflow: auto;
        margin-top: auto;
        .MuiPaper-root {
            width: 100%;
            padding: 0px;
            background-color: transparent;
        }
        .profile-details-section {
            border-bottom: 1px solid $border-color;
            padding: 24px;
            background-color: #fff;
            .MuiTextField-root {
                width: 100%;
                height: 55px;
            }
            .mobile-name-fields {
                display: flex;
                flex-direction: column;
                gap: 36px;
            }
            .mobile-phoneNumber-fields {
                border-bottom: none;
                padding-bottom: 0;
                gap: 16px;
                overflow: hidden;
                .MuiTextField-root {
                    width: 34%;
                    height: 55px;
                }
                .MuiStack-root {
                    .MuiTextField-root {
                        width: 100%;
                        height: 55px;
                    }
                }
                @media (min-width: 600px) {
                    .MuiTextField-root {
                        width: 15%;
                    }
                    .MuiStack-root {
                        width: 100%;
                    }
                }
            }
        }

        .assign-role-section {
            border-bottom: 1px solid $border-color;
            border-top: 1px solid $border-color;
            padding: 24px;
            background-color: #fff;
            margin: 16px 0;
            .css-nen11g-MuiStack-root {
                border: none;
                margin: 0;
                padding: 0;
            }
            .MuiTypography-body1 {
                display: block;
            }
            .css-17em4y2-MuiStack-root {
                padding: 0 9px 24px 9px;
                margin-bottom: 16px;
            }
            .MuiTextField-root {
                width: 100%;
                height: 55px;
            }
        }
        .mobile-chip-wrapper {
            margin: 0;
            .MuiChip-root {
                margin: 0 8px 8px 8px;
            }
        }
        .additional-roles-section {
            padding: 24px;
            background-color: #fff;
            .action-button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 0;
            }
        }
        .action-button {
            margin-top: 16px;
            width: 100%;
            padding: 0 12px;
            .MuiSvgIcon-root {
                font-size: 14px;
            }
        }
        .button-section {
            z-index: 99;
            flex-direction: column-reverse;
            align-items: center;
            box-shadow: 0px -1px 18px 0px rgba(0, 0, 0, 0.12);
            width: 100%;
            position: fixed;
            bottom: 0px;
            left: 0px;
            background: #fff;
            padding: 16px;
            gap: 16px;
            margin: 0px;

            .MuiButton-root {
                width: 100%;
                margin: 0;
                height: 36px;
            }
        }
    }
}
