.energy-flow-wrapper-padding {
    padding-left: 8px;
    padding-top: 8px;
}
.energy-flow-wrapper {
    position: relative;
    z-index: 1;
    height: calc(60vh - 100px);
    overflow-y: auto;

    &:nth-child(3) {
        & .card {
            right: 20%;
        }
    }

    .widget-top {
        position: absolute;
        top: 8px;
        width: 33.33%;
        height: 33.33%;
    }

    .widget-center {
        position: absolute;
        top: 50%;
        bottom: 50%;
        width: 33.33%;
        height: 33.33%;
        transform: translateY(-50%);
    }

    .widget-bottom {
        position: absolute;
        bottom: 0%;
        width: 33.33%;
        height: 33.33%;
        z-index: -1;
    }

    .organization-wrapper {
        @extend .widget-center;
        z-index: 10;

        & .organization {
            position: relative;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}
