.custom-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: white;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.legend-item:last-child {
    margin-right: 0;
}

.legend-label {
    margin-left: 0.5rem; /* Equivalent to ml-2 */
    font-size: 0.875rem; /* Equivalent to text-sm */
    color: #4b5563; /* Equivalent to text-gray-600 */
}
