// base colors
$primary-50: #e0eef8;
$primary: #007bc1;
$text-secondary: #556167;
$icon-color: #879196;
$content-color: #727e84;
$bg-content: #eef0f0;
$border-color: #f0ebeb;
$content-wrapper: #f7f8f8;
$list-active-item: #007bc10d;
$warning-color: #f0a920;
$danger-color: #ca3c3d;
$success-color: #39b620;
$error-btn: #bd2e2f;
$header-bg: #fbfbfb;
$disable-color: #e59e9e;
$disabled-text: #c7cacc;
$disabled-link: #424e544d;
$disabled-bg-color: #f9e8e8;
$list-item-hover-bg: #d7ecf7;
$icon-light-color: #d5d8da;
$icon: #eaeaf0;

//bright-layer colors
$red-50: #f9e8e8;
$red-600: #c53637;
$red-400: #f68b42;
$blue-200: #80bde0;
$blue-300: #4d8ec4;
$blue-400: #269af4;
$blue-500: #0088f2;
$blue-600: #0080f0;
$black-300: #7b8387;
$black-400: #5e696e;
$black-700: #333d43;
$black-900: #1d2529;
$black-500: #424e54;
$sky-50: #f1f8fa;
$sky-100: #dbeef2;
$sky-800: #69b1c3;
$sky-500: #87c6d4;
$gold-50: #fdf5e4;
$gold-500: #f0aa1f;
$gold-600: #eea31b;
$gold-700: #ec9917;
$gold-800: #e99012;
$gold-900: #e57f0a;
$goldenrod-500: #fef7e7;
$goldenrod-700: #f2b127;
$orange-50: #feefe4;
$green-50: #e7f6e4;
$light-green: #94c027;
$green-700: #2ca618;
$gray-50: #eef0f0;
$gray-200: #b9bfc2;
$gray-300: #9ca5a9;
$gray-700: #5f6b71;
$green-500: #4caf50;
$sunset-500: #eb8f2b;
$sunset-700: #e57c20;
$sunset-800: #e2721a;
$orange-500: #f47721;
$orange-700: #f16418;
$black-500: #424e54;
$yellow-500: #f0cb2f;
$grade: #f0cb2e;
$purple-500: #983fef;
$teal-500: #00b2a9;
$citron-500: #bcc92d;

// custom colors
$white: #fff;
$black: #000;
$light-blue-avatar: #f2f8fc;
$light-blue: #f2f8fc;

//scrollbar-color
$scrollbar: rgba(0, 0, 0, 0.3);
