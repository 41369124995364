.multi-select {
    @extend .padding-y-9;
    @extend .padding-x-16;
    @extend .content-wrapper;
    border-radius: 4px 4px 0px 0px;
    .MuiSelect-select {
        @extend .padding-0;
    }
}

.myProfile-empty-state {
    @extend .justify-content-start;
    .BluiEmptyState-icon {
        @extend .margin-bottom-0;
        @extend .color-icon-light;
        .MuiAvatar-root {
            @extend .width-96;
            @extend .height-96;
        }
    }
    .BluiEmptyState-actions {
        @extend .margin-bottom-8;
    }
}
