@use 'sass:math';

/*---------- flex Mixin e.g use mixin "@include flex-1" ----------*/
@mixin flex-1 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0% !important;
}
/*---------- End" ----------*/

/*---------- flex Mixin e.g use className ".flex-10" ----------*/
$max: 100;
$offset: 1;
$unit: '%';

@mixin flex($className, $styleName1, $styleName2) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName1}: #{$i + $unit} !important;
            #{$styleName2}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include flex('.flex-', 'flex-basis', 'max-width');
/*---------- End ----------*/

/*---------- width Mixin e.g use className "width-5"  ----------*/

$max: 1500;
$offset: 1;
$unit: 'px';

@mixin width($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include width('.width-', 'width');
/*---- End -------*/

/*---------- min-width Mixin e.g use className "min-width-5"  ----------*/

$max: 500;
$offset: 1;
$unit: 'px';

@mixin min-width($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include min-width('.min-width-', 'min-width');
/*---- End -------*/

/*---------- max-width Mixin e.g use className "max-width-5"  ----------*/

$max: 1500;
$offset: 1;
$unit: 'px';

@mixin max-width($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include max-width('.max-width-', 'max-width');
/*---- End -------*/

/*---------- height Mixin e.g use className "height-10"  ----------*/
$max: 1000;
$offset: 1;
$unit: 'px';

@mixin height($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include height('.height-', 'height');
/*---- End -------*/

/*---------- min-height Mixin e.g use className "min-height-10"  ----------*/
$max: 1000;
$offset: 1;
$unit: 'px';

@mixin min-height($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include min-height('.min-height-', 'min-height');
/*---- End -------*/

/*---------- max-height Mixin e.g use className "max-height-10"  ----------*/
$max: 1000;
$offset: 1;
$unit: 'px';

@mixin max-height($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include max-height('.max-height-', 'max-height');
/*---- End -------*/

/*-------------- Dynamic classes for z-index e.g. z-index-5 -----------*/
$max: 20;
$offset: 1;

@mixin z-index($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i};
        }

        $i: $i + $offset;
    }
}

@include z-index('.z-index-', 'z-index');
/*----------------- End ------------------*/

/*---------- font-size Mixin e.g use className  "f-14"  ----------*/

$max: 60;
$offset: 1;
$unit: 'px';

@mixin font($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include font('.f-', 'font-size');
/*---- End -------*/

/*---------- font-weight Mixin e.g use className  "fw-400"  ----------*/

$max: 900;
$offset: 100;

@mixin font($className, $styleName) {
    $i: 100;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i} !important;
        }

        $i: $i + $offset;
    }
}

@include font('.fw-', 'font-weight');
/*---- End -------*/

/*---------- custom padding-left mixin ----------*/
@mixin custom-padding-left($padding-left) {
    padding-left: $padding-left;
}
/*----------------- End ------------------*/

/*---------- custom padding-right mixin ----------*/
@mixin custom-padding-right($padding-right) {
    padding-right: $padding-right;
}
/*----------------- End ------------------*/

/*---------- custom padding-top mixin ----------*/
@mixin custom-padding-top($padding-top) {
    padding-top: $padding-top;
}
/*----------------- End ------------------*/

/*---------- custom padding-bottom mixin ----------*/
@mixin custom-padding-bottom($padding-bottom) {
    padding-bottom: $padding-bottom;
}
/*----------------- End ------------------*/

/*---------- Single Line Padding Mixin e.g. @include padding(0px, 20px, 0px, 20px);  ----------*/
@mixin padding($top, $right, $bottom, $left) {
    padding-top: $top !important;
    padding-right: $right !important;
    padding-bottom: $bottom !important;
    padding-left: $left !important;
}

/*---- End -------*/

/*---------- Padding All side Mixin e.g use className "padding-15" ----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-('.padding-', 'padding');
/*---- End -------*/

/*-------------- Dynamic classes for vertical padding e.g. padding-y-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-top-bottom($className, $styleName1, $styleName2) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName1}: #{$i + $unit};
            #{$styleName2}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-top-bottom('.padding-y-', 'padding-top', 'padding-bottom');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for horizontal padding e.g. padding-x-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-left-right($className, $styleName1, $styleName2) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName1}: #{$i + $unit};
            #{$styleName2}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-left-right('.padding-x-', 'padding-left', 'padding-right');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for padding-top e.g. padding-top-5-----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-top($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-top('.padding-top-', 'padding-top');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for padding-right e.g. padding-right-5-----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-right($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-right('.padding-right-', 'padding-right');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for padding-bottom e.g. padding-bottom-5-----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-bottom($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-bottom('.padding-bottom-', 'padding-bottom');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for padding-left e.g. padding-left-5-----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin padding-left($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include padding-bottom('.padding-left-', 'padding-left');
/*----------------- End ------------------*/

/*---------- Single Line Margin mixin  e.g. @include margin(0px, 20px, 0px, 20px);----------*/
@mixin margin($top, $right, $bottom, $left) {
    margin-top: $top !important;
    margin-right: $right !important;
    margin-bottom: $bottom !important;
    margin-left: $left !important;
}

/*---- End -------*/

/*---------- Margin All side Mixin e.g use className "margin-15"  ----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-('.margin-', 'margin');
/*---- End -------*/

/*-------------- Dynamic classes for vertical Margin e.g. margin-y-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-top-bottom($className, $styleName1, $styleName2) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName1}: #{$i + $unit};
            #{$styleName2}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-top-bottom('.margin-y-', 'margin-top', 'margin-bottom');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for horizontal margin e.g. margin-x-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-left-right($className, $styleName1, $styleName2) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName1}: #{$i + $unit};
            #{$styleName2}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-left-right('.margin-x-', 'margin-left', 'margin-right');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for margin-top e.g. margin-top-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-top($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-top('.margin-top-', 'margin-top');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for margin-right e.g. margin-right-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-right($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-right('.margin-right-', 'margin-right');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for margin-bottom e.g. margin-bottom-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-bottom($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-bottom('.margin-bottom-', 'margin-bottom');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for margin-left e.g. margin-left-20 -----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin margin-left($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include margin-left('.margin-left-', 'margin-left');
/*----------------- End ------------------*/

/*---------- line-height mixin ----------*/
@mixin custom-line-height($line-height) {
    line-height: $line-height !important;
}

$max: 35;
$offset: 1;
$unit: 'px';

@mixin line-height-($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include line-height-('.line-height-', 'line-height');
/*---------- End ----------*/

/*---------- gap All side Mixin e.g use className "gap-15"  ----------*/
$max: 100;
$offset: 1;
$unit: 'px';

@mixin gap-($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include gap-('.gap-', 'gap');
/*---- End -------*/

/*---------- Single Line border mixin  e.g. @include custom-border-radius(0px, 20px, 0px, 20px);----------*/
@mixin custom-border-radius($top-left, $top-right, $bottom-right, $bottom-left) {
    border-top-left-radius: $top-left !important;
    border-top-right-radius: $top-right !important;
    border-bottom-right-radius: $bottom-right !important;
    border-bottom-left-radius: $bottom-left !important;
}

@mixin border-radius-top-left($top-left) {
    border-top-left-radius: $top-left !important;
}

@mixin border-radius-top-right($top-right) {
    border-top-right-radius: $top-right !important;
}

@mixin border-radius-bottom-right($bottom-right) {
    border-bottom-right-radius: $bottom-right !important;
}

@mixin border-radius-bottom-left($bottom-left) {
    border-bottom-left-radius: $bottom-left !important;
}

/*---- End -------*/

/*-------------- Dynamic classes for Border-Radius e.g. border-radius-5 -----------*/
$max: 50;
$offset: 1;
$unit: 'px';

@mixin border-radius($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include border-radius('.border-radius-', 'border-radius');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for width in percentage e.g. w-5 -----------*/
$max: 100;
$offset: 1;
$unit: '%';

@mixin width-p($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include width-p('.w-', 'width');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for height in percentage e.g. min-w-5 -----------*/
$max: 100;
$offset: 1;
$unit: '%';

@mixin min-width-p($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include min-width-p('.min-w-', 'min-width');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for height in percentage e.g. h-5 -----------*/
$max: 100;
$offset: 1;
$unit: '%';

@mixin height-p($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include height-p('.h-', 'height');
/*----------------- End ------------------*/

/*-------------- Dynamic classes for height in percentage e.g. max-h-5 -----------*/
$max: 100;
$offset: 1;
$unit: '%';

@mixin max-height-p($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }

        $i: $i + $offset;
    }
}

@include max-height-p('.max-h-', 'max-height');
/*----------------- End ------------------*/

/*-------------- Bg Colors For Alerts e.g. @include alerts-bg-colors(warning);  -----------*/

/*---------- letter spacing Mixin e.g use className "letter-spacing-10"  ----------*/
$max: 10;
$offset: 1;
$unit: 'px';

@mixin letter-spacing($className, $styleName) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@include letter-spacing('.letter-spacing-', 'letter-spacing');
/*---- End -------*/

//  transforms
@mixin transform-center-align {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin image {
    @extend .w-100;
    @extend .h-100;
    object-fit: contain;
    object-position: center;
}

@mixin text-truncate {
    white-space: nowrap;
    @extend .overflow-hidden;
    text-overflow: ellipsis;
}

@mixin content($content, $width, $height) {
    content: '';
    width: $width;
    height: $height;
}

@mixin fs-small {
    font-size: 12px;
}

@mixin fw-normal {
    font-weight: 400 !important;
}

@mixin flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
