/*-- border classes start --*/

.border-none {
    border: none !important;
}

.border-error {
    border: 1px solid $error-btn !important;
}

.border-1 {
    border: 1px solid $border-color;
}

.border-2 {
    border: 2px solid #d5d8da;
}

.border-top-1 {
    border-top: 1px solid $border-color;
}

.border-bottom-1 {
    border-bottom: 1px solid $border-color;
}

.border-top-2 {
    border-top: 2px solid $border-color;
}

.border-bottom-2 {
    border-bottom: 2px solid $border-color;
}

.border-left-1 {
    border-left: 1px solid $border-color;
}

.border-right-1 {
    border-right: 1px solid $border-color;
}

.border-dashed-2 {
    border: 2px dashed $border-color;
}

.border-dashed-3 {
    border: 3px dashed $border-color;
}

.border-bottom-none {
    border: 1px solid $border-color;
    border-bottom: none;
}

.border-warning {
    border: 2px solid #f0aa1f;
}

.border-primary {
    border: 2px solid $primary;
}

.border-top-content-1 {
    border: 1px solid $content-color;
}

.border-top-primary-1 {
    border-top: 1px solid $primary;
}

.border-right-primary-1 {
    border-right: 1px solid $primary;
}

.border-bottom-primary-1 {
    border-bottom: 1px solid $primary;
}

.border-left-primary-1 {
    border-left: 1px solid $primary;
}

.border-collapse {
    border-collapse: collapse;
}

.border-y-1 {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

/*-- border classes end --*/

/*--- border-radius classes start ---*/
.border-radius-top-left {
    @include border-radius-top-left(4px);
}

.border-radius-top-right {
    @include border-radius-top-right(4px);
}

.border-radius-bottom-right {
    @include border-radius-bottom-right(4px);
}

.border-radius-bottom-left {
    @include border-radius-bottom-left(4px);
}

/*--- border-radius classes end ---*/

/*---text align classes start --*/

.text-align-end {
    text-align: end;
}

.text-align-start {
    text-align: start;
}

.text-align-center {
    text-align: center;
}

.text-align-justify {
    text-align: justify;
}

/*---text align classes end --*/

/*--- text-transform classes start ---*/

.text-uppercase {
    text-transform: uppercase;
}

/*--- text-transform classes start ---*/

/*--- text-truncate class start ---*/
.text-truncate {
    white-space: nowrap;
    @extend .overflow-hidden;
    text-overflow: ellipsis;
}

/*--- text-truncate class end ---*/

/*--- cursor classes start --*/

.cursor-pointer {
    cursor: pointer;
}

/*--- cursor classes start --*/

/*--- overflow classes start ---*/

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

/*--- overflow classes end ---*/

/*--- classes for positions start ---*/

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.custom-position {
    .MuiMenu-paper {
        position: sticky;
        top: 50px !important;
        width: 150px;
    }
}

/*--- classes for positions end ---*/

/*--- display classes start ---*/

.display-block {
    display: block;
}

.display-none {
    display: none;
}

/*--- display classes start ---*/

/*--- transform classes start ---*/
.rotate-0 {
    transform: rotate(0deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

/*--- transform classes end ---*/

/*--- class for position center ---*/
.center {
    @include transform-center-align;
}

/*--- width auto ---*/
.width-auto {
    width: auto;
}

/*--- margin-auto ---*/
.margin-auto {
    margin: auto;
}

.margin-0-auto {
    margin: 0 auto;
}

/*--- height-100vh ---*/
.height-100vh {
    height: 100vh;
}

/*--- realm List custom Height ---*/
.realm-list-height {
    height: calc(100vh - 141px);
}

/*--- box shadow classes ---*/
.box-shadow {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.2);
}

/*-- disable hover color --*/
.list-item-hover {
    .MuiListItemButton-root {
        &:hover {
            background: none;
        }
    }
}

.tag-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 254px;
    overflow: hidden;
    display: block;
    text-align: right;
}
.description-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 205px;
    overflow: hidden;
    display: block;
    text-align: right;
}

/*--- pointer events classes ---*/
.pointer-events-none {
    pointer-events: none;
}

.pointer-events-all {
    pointer-events: all;
}

/*--- classes for filter start ---*/
.filter-blur {
    filter: blur(2px);
}

/*--- classes for filter end ---*/

/*--- class for position sticky ---*/
.position-sticky {
    position: sticky;
    top: 0;
}

.position-sticky-bottom {
    position: sticky;
    bottom: 0;
}

/*--- class for avatar custom background ---*/
.bg-avatar {
    background-color: #f2f8fc;
}

/*--- class for visibility hidden<Badge badgeContent={1} className="custom-badge"> ---*/
.visibility-hidden {
    visibility: hidden;
}
/*--- class for common container ---*/
.container {
    height: calc(100vh - 126px);
    @extend .overflow-y-scroll;
}

.notistack-with-icon {
    background-color: #1d2529;
    display: flex;
    min-width: 365px;
    height: 72px;
    padding: 15.5px 16px 15.5px 16px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    color: #ffffff;

    .notistack-text-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        .notistack-icon {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .notistack-title {
            color: #e8eaea;
            font-size: 16px;
            font-weight: 600;
        }

        .notistack-subtitle {
            color: #a1a7aa;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.pac-container {
    z-index: 9999999 !important;
}

.pac-logo:after {
    background-image: none;
}

.pac-item {
    line-height: 41px;
}
