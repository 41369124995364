.select-field {
    margin-bottom: 16px;
}

.divider {
    width: calc(100% + 16px);
}

.bottom-divider {
    margin-bottom: 16px;
}

.empty-state {
    justify-content: 'flex-start';
    .BluiEmptyState-icon {
        & .MuiAvatar-root {
            width: 96px;
            height: 96px;
        }
    }
}

.upload-icon {
    @extend .border-dashed-2;
    border-radius: 50%;
    @extend .bg-header;
    @extend .text-primary;
    padding: 16px;
}

.mui-dialog-paper {
    .MuiPaper-root {
        @extend .max-width-720;
        @extend .width-720;
        @extend .min-height-300;
    }
}

.mui-dialog-title-root {
    padding-top: 32px;
}

.dialog-content {
    min-height: 334px;
}

.mui-dialog-actions-root {
    padding: 24px;
    @extend .border-top-1;
}

.list-wrapper {
    padding: 0px;
}

.list-item {
    .BluiInfoListItem-statusStripe {
        background: transparent;
    }
}

.list-item-active {
    background: #007bc10d;
    @extend .text-primary;
    .BluiInfoListItem-subtitle {
        @extend .text-primary;
    }
}

.delete-button {
    @extend .bg-red-400;
    &:hover {
        @extend .red-400;
    }
    .Mui-disabled {
        background-color: #f9e8e8;
        color: #ee9e9e;
    }
}
